const prefix = process.env.GATSBY_ENVIRONMENT;

// Liste des fields sur la Sandbox et la prod (Zendesk), l'ID des fields correspond au parent
/* const fieldsSandbox = {
  objet_de_la_demande: 29637883434260,
  informations_d_ordre_générale: 29637883425300,
  passer_une_nouvelle_commande_: 29637909536276,
  accompagnement_devis: 29637878962580,
  informations_sur_une_commande_en_cours_: 29637848730260,
  information_sur_votre_réclamation: 29637883400852,
  informations_sur_mon_compte: 29637849073556,
  information_sur_vos_factures_vos_reglements: 29637832755988
} as const

const fieldsProduction = {
  objet_de_la_demande: 8457142719252,
  informations_d_ordre_générale: 8457273044372,
  passer_une_nouvelle_commande_: 8458588943892,
  accompagnement_devis: 23888548513940,
  informations_sur_une_commande_en_cours_: 8457657017236,
  information_sur_votre_réclamation: 8457686986516,
  informations_sur_mon_compte: 8458679642132,
  information_sur_vos_factures_vos_reglements: 29542938059796
} as const */

const objet_de_la_demande = prefix === 'production' ? 8457142719252 : 29637883434260;
const informations_d_ordre_générale = prefix === 'production' ? 8457273044372 : 29637883425300;
const passer_une_nouvelle_commande_ = prefix === 'production' ? 8458588943892 : 29637909536276;
const accompagnement_devis = prefix === 'production' ? 23888548513940 : 29637878962580;
const informations_sur_une_commande_en_cours_ = prefix === 'production' ? 8457657017236 : 29637848730260;
const information_sur_votre_réclamation = prefix === 'production' ? 8457686986516 : 29637883400852;
const informations_sur_mon_compte = prefix === 'production' ? 8458679642132 : 29637849073556;
const information_sur_vos_factures_vos_reglements = prefix === 'production' ? 29542938059796 : 29637832755988;

export const RequestType = {
  // First Level
  //Objet de la demande

  '': 0,
  informations_d_ordre_générale: objet_de_la_demande,
  passer_une_nouvelle_commande_: objet_de_la_demande,
  accompagnement_devis: objet_de_la_demande,
  informations_sur_une_commande_en_cours_: objet_de_la_demande,
  information_sur_votre_réclamation: objet_de_la_demande,
  informations_sur_mon_compte: objet_de_la_demande,
  information_sur_vos_factures_vos_reglements: objet_de_la_demande,

  // Second Level
  // informations_d_ordre_générale
  s_inscrire__sur_exaprint: informations_d_ordre_générale,
  problèmes_rencontrés_sur_la_navigation_sur_le_site_web: informations_d_ordre_générale,

  // passer_une_nouvelle_commande_
  obtenir_une_information_sur_un_produit__une_finition__un_service: passer_une_nouvelle_commande_,
  modalité_de_livraison: passer_une_nouvelle_commande_,
  préparation_de_votre_fichier: passer_une_nouvelle_commande_,
  aide_sur_les_codes_promo: passer_une_nouvelle_commande_,
  règlement_de_votre_commande: passer_une_nouvelle_commande_,
  demande_d_échantillons: passer_une_nouvelle_commande_,

  // accompagnement_devis
  'demande_de_multipoint_point_de_livraison_sur_produit_gamme_catalogue__n°_de_devis_obligatoire_': accompagnement_devis,
  demande_de_devis_sur_mesure_sur_produit_hors_gamme_catalogue__: accompagnement_devis,

  // informations_sur_une_commande_en_cours_
  délai_de_livraison: informations_sur_une_commande_en_cours_,
  modification_de_l_adresse_de_livraison: informations_sur_une_commande_en_cours_,
  annulation_de_commande: informations_sur_une_commande_en_cours_,
  commande_bloquée__fichier: informations_sur_une_commande_en_cours_,
  probleme_de_livraison: informations_sur_une_commande_en_cours_,
  commande_bloquee_paiement: informations_sur_une_commande_en_cours_,
  factures: informations_sur_une_commande_en_cours_,
  autre: informations_sur_une_commande_en_cours_,

  // information_sur_votre_réclamation
  reclamation_livraison: information_sur_votre_réclamation,
  'reclamation_qualite_/_conformite_produit': information_sur_votre_réclamation,
  demande_reclamation_hors_delais: information_sur_votre_réclamation,

  // informations_sur_mon_compte
  programme_exaclub: informations_sur_mon_compte,
  mise_à_jour_des_données_du_compte: informations_sur_mon_compte,
  suppression_de_compte__rgpd_: informations_sur_mon_compte,
  probleme_de_connexion__mot_de_passe_: informations_sur_mon_compte,
  désinscription_de_la_news_letter: informations_sur_mon_compte,
  obtenir_des_informations_sur_mon_encours: informations_sur_mon_compte,

  // information_sur_vos_factures_vos_reglements
  factures_et_releve: information_sur_vos_factures_vos_reglements,
  informations_sur_un_reglement: information_sur_vos_factures_vos_reglements,
  mandat_administratif: information_sur_vos_factures_vos_reglements,
  ordre_virement: information_sur_vos_factures_vos_reglements,
  demande_de_prelevement: information_sur_vos_factures_vos_reglements
} as const;

export type RequestTypeKey = keyof typeof RequestType;

export const mappingFunc = (requestType: RequestTypeKey) => {
  return RequestType[requestType as keyof typeof RequestType];
};

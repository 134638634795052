import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

const StyledMarkdown = styled.div`
  word-break: break-word !important;
  vertical-align: unset !important;
  font-weight: normal !important;
  font-size: 14px !important;

  a {
    color: #3b82f6 !important;
    text-decoration: underline !important;
  }

  ol,
  ul {
    margin: 0;
    padding-left: 1.5rem;
    list-style-type: disc;
  }
  li {
    margin: 0.5em 0;
  }
`;

const LinkRenderer = (props: any) => (
  <a href={props.href} target="_blank" rel="noopener noreferrer">
    {props.children}
  </a>
);

export const MessageContent = ({ content }: { content: string }) => {
  return (
    <StyledMarkdown>
      <ReactMarkdown
        source={content}
        renderers={{
          link: LinkRenderer
        }}
      />
    </StyledMarkdown>
  );
};

import { useEffect, useState } from 'react';

export const useIsAbTest = (campaignId: number, variationName: string) => {
  const [enabled, setEnabled] = useState(false);

  const enableAbTest = (campaignDetails: any) => {
    console.log(`AbTest ${campaignId} enabled for variation : ${variationName}`, campaignDetails);
    setEnabled(true);
  };

  const detectAbTestOnPage = () => {
    const ABTasty = (window as any)?.ABTasty;
    if (ABTasty) {
      const tests = ABTasty?.getTestsOnPage();
      const currentTest = tests[campaignId];
      if (currentTest && currentTest.variationName === variationName) {
        enableAbTest(currentTest);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('abtasty_executedCampaign', detectAbTestOnPage);
    detectAbTestOnPage();
    return () => window.removeEventListener('abtasty_executedCampaign', detectAbTestOnPage);
  }, []);

  return enabled;
};

import React, { FunctionComponent } from 'react';
import { useIsAbTest } from '../../hooks/useIsAbTest';

interface Props {
  campaignId: number;
  variationName: string;
}
export const AbTest: FunctionComponent<Props> = ({ campaignId, variationName, children }) => {
  const isAbTest = useIsAbTest(campaignId, variationName);
  if (!isAbTest) return null;

  return <>{children}</>;
};

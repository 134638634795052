import React, { ReactNode, FunctionComponent, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import 'cross-fetch/polyfill';
import 'regenerator-runtime/runtime';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SearchComponent from '../components/Search';
import Container from '../components/Container';
import Wrapper from '../components/Wrapper';

import './Layout.scss';
import '../../assets/css/custom.css';
import '../../assets/css/fonts.css';

import PrintboxBigNavigation from './PrintboxBigNavigation';

import { initI18n } from '../localization/i18n';

// Thoses translations file are injected on build according to the current locale (more details at: gatbsy-node.js onPreInit)
import translations from './../../dist/translations/translations.json';
import sharedComponents from './../../dist/translations/sharedComponents.json';

import { FooterCollection, SimpleContentCollection } from '../generated/contentful-generated-types';
import { NewFooterComponent } from './Footer/NewFooterComponent';
import { TBreadcrumb } from './breadcrumb';

import { getUrlOf } from '../utils/urls';
import { Header } from '../navigation/Header';
import { PrintboxTopNavigation } from '../navigation/PrintboxTopNavigation';
import { MainHeader } from '../navigation/MainHeader';
import { BottomNavigation } from '../navigation/BottomNavigation';
import { MenuItem } from './menu/MenuUtils';
import { gtm } from '../common/GTMScript';
import { DebugModeProvider } from './Debug/DebugModeProvider';
import { LayoutContext } from './LayoutContext';
import { LoginFormContextProvider } from '../login/LoginFormContext';
import { DidomiConsentContextProvider } from './Consent/DidomiConsentContext';
import { ChatWidgetV3 } from './ChatWidget/ChatWidget';
import { AbTest } from '../common/components/AbTest';
import { useIsAbTest } from '../hooks/useIsAbTest';

const favicon = require('../../assets/icons/exaprint-favicon.ico');

interface Data {
  menuData: {
    content: MenuItem[];
  };
  site: {
    siteMetadata: {
      title: string;
    };
  };
  contentful: {
    simpleContentCollection: SimpleContentCollection;
  };
}

const locale = process.env.GATSBY_LOCALE || 'fr';
const isFR = locale === 'fr';
initI18n(locale, { translations, sharedComponents });

interface RenderingOptions {
  metaLang: string;
  cartUrl: string;
  lostPasswordUrl: string;
  baseUrl: string;
  children: React.ReactNode;
  hideNavigation?: boolean;
  pageType: 'CategoryPage' | 'InknpaperPage' | 'PPAGCategoryPage' | 'home' | 'LandingPage' | 'SimplePage';
  footerCollection: FooterCollection;
  locationHref?: string;
  isMinimalistHeader: boolean;
}

const exaprintRendering = (options: RenderingOptions) => (data: Data) => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const topBanners = data.contentful?.simpleContentCollection?.items.filter(simpleContent => simpleContent && simpleContent.locale === locale) || undefined;

  useEffect(() => {
    layoutRef.current?.scrollIntoView();
  }, [options.locationHref]);

  const isAbTest = useIsAbTest(1362388, 'Variation 1');
  const prefix = process.env.GATSBY_ENVIRONMENT == 'production' ? 'prd' : 'dev';
  const isProduction = prefix === 'prd';
  return (
    <div ref={layoutRef}>
      <DidomiConsentContextProvider>
        <LayoutContext.Provider value={{ layoutRef }}>
          <Helmet>
            <title>{data.site.siteMetadata.title}</title>
            <link rel="shortcut icon" type="image/x-icon" href={`${favicon}`} />
            <meta name="language" content={options.metaLang} />
            {gtm}
            <body className={locale} />
          </Helmet>
          <LoginFormContextProvider locationHref={options.locationHref}>
            <DebugModeProvider>
              <ToastContainer autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover />
              <StyledWrapper feature="inknpaper" id="main-wrapper">
                <Header
                  hideNavigation={options.hideNavigation}
                  pageType={options.pageType}
                  rootMenuItems={data.menuData?.content}
                  topBanners={topBanners}
                  isMinimalistHeader={options?.isMinimalistHeader}
                />
                <InknPaperContent>
                  {isFR && isProduction && (
                    <AbTest campaignId={1362388} variationName="Variation 1">
                      <ChatWidgetV3 apiUrl={`https://ai-api-${prefix}.exaprint.fr/exabotchat/invoke`} />
                    </AbTest>
                  )}
                  {isFR && !isProduction && <ChatWidgetV3 apiUrl={`https://ai-api-${prefix}.exaprint.fr/exabotchat/invoke`} />}
                  <Container isInknpaper>
                    <ContentBlock>{options.children}</ContentBlock>
                  </Container>
                  <NewFooterComponent blocks={options?.footerCollection?.items[0]?.blocksCollection ?? undefined} />
                </InknPaperContent>
              </StyledWrapper>
            </DebugModeProvider>
          </LoginFormContextProvider>
        </LayoutContext.Provider>
      </DidomiConsentContextProvider>
      <style>
        {/**dirty hack to hide the yellow help button displayed on all pages */}
        {` #launcher{display:${isAbTest ? 'none' : 'initial'}}`}
      </style>
    </div>
  );
};

const StyledWrapper = styled(Wrapper)`
  margin-bottom: 50px;
`;

const ContentBlock = styled.div`
  max-width: 1720px;
  margin: 40px auto 0px auto;
  @media (max-width: 620px) {
    margin: auto;
  }
`;
const InknPaperContent = styled.div`
  flex-grow: 2;
`;

// Add id="main-wrapper" to Wrapper, allowing customisation in the Simple Page packages/gatsby/src/content/SimplePage.tsx
const printboxRendering = (options: RenderingOptions) => (data: Data) => {
  return (
    <>
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link rel="shortcut icon" type="image/x-icon" href={`${favicon}`} />
        <meta name="language" content={options.metaLang} />
        {gtm}
        <body className={locale} />
      </Helmet>
      <PrintboxTopNavigation cartUrl={options.cartUrl} baseUrl={options.baseUrl}>
        <SearchComponent />
      </PrintboxTopNavigation>
      <ToastContainer autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} draggable pauseOnHover />
      <Wrapper id="main-wrapper">
        <MainHeader homeUrl={options.baseUrl} />
        <Container>
          <PrintboxBigNavigation />
          {options.children}
        </Container>
        <BottomNavigation />
      </Wrapper>
    </>
  );
};

interface Props {
  children?: ReactNode;
  location: {
    href?: string;
  };
  pageContext: {
    isInknpaper: boolean;
    breadcrumbs: TBreadcrumb[];
    hideNavigation?: boolean;
    pageType: 'CategoryPage' | 'InknpaperPage' | 'home';
    footerCollection: FooterCollection;
    isMinimalistHeader?: boolean;
  };

  data?: {
    mainMenu?: {
      content: MenuItem[];
    };
  };
}

export const Layout: FunctionComponent<Props> = props => {
  const {
    children,
    location: { href },
    pageContext: { isInknpaper, hideNavigation, pageType, footerCollection, isMinimalistHeader = false }
  } = props;
  const baseUrl = getUrlOf('ams');
  const cartUrl = getUrlOf('cart');
  const lostPasswordUrl = `${getUrlOf('auth')}/api/v1/lostpassword`;

  const metaLang = `${locale}-${locale.toUpperCase()}`;

  const renderingOptions: RenderingOptions = {
    locationHref: href,
    metaLang,
    cartUrl,
    lostPasswordUrl,
    baseUrl,
    children,
    hideNavigation,
    pageType,
    footerCollection,
    isMinimalistHeader
  };

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          menuData {
            content
          }
          site {
            siteMetadata {
              title
            }
          }
          contentful {
            simpleContentCollection(where: { simpleContentLocation: "Top Banner" }) {
              items {
                contentfulName
                locale
                description {
                  json
                }
              }
            }
          }
        }
      `}
      render={isInknpaper ? exaprintRendering(renderingOptions) : printboxRendering(renderingOptions)}
    />
  );
};

import React, { useState, useRef, useEffect, FormEvent, ChangeEvent } from 'react';
import axios from 'axios';
import { MessageCircle, X, ChevronDown, ArrowUp, ChevronLeft } from 'lucide-react';
import styled, { keyframes } from 'styled-components';
import { SimpleFileUpload } from './SimpleFileUpload';
import { renderSecondLevel } from './RenderSecondLevel';
import { mappingFunc, RequestTypeKey } from '../../utils/chatbot/idFieldMapping';
import { renderOptionalFields } from './RenderOptionalFields';
import { MessageContent } from './MessageContent';
import { useIsWindowWidthBelow } from '../../hooks/useWindowSize';
import user3 from '../../../assets/img/christopher-barrat.jpg';
import user2 from '../../../assets/img/remy-finet.jpg';
import user1 from '../../../assets/img/thibault-rouet.jpg';
import aiIcon from '../../../assets/img/ExaTest.svg';
import { createGlobalStyle } from 'styled-components';

export type ChatMessage = [string, string];
interface ChatMessageResponse {
  answer: string;
  categories: string;
  question: string;
  ticket_id: number;
}

interface ChatWidgetProps {
  apiUrl: string;
}

type FormType = 'ticket' | 'callback';

export type FormState = {
  reason?: string;
  userName: string;
  email: string;
  phoneNumber: string;
  subject: string;
  description: string;
  requestType: RequestTypeKey;
  secondRequestType: RequestTypeKey;
  requestTypeID: number;
  secondRequestTypeID: number;
  quote_number: number;
  delivery_points_number: number;
  order_number: number;
  account_number: number;
  attachment_token: string;
  formType: FormType;
};

export type TypeHandleChangeForm = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
function setWithExpiry(key: string, value: any, ttl: number) {
  const now = new Date();

  const item = {
    value: JSON.stringify(value),
    expiry: now.getTime() + ttl
  };

  if (typeof window !== 'undefined') {
    localStorage.setItem(key, JSON.stringify(item));
  }
}

function getWithExpiry(key: string) {
  if (typeof window !== 'undefined') {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
      return null;
    }

    try {
      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }

      return item.value;
    } catch (error) {
      console.error("Erreur lors de l'analyse de getWithExpiry:", error);
      return null;
    }
  }
}

const GlobalStyle = createGlobalStyle<{ isChatOpen: boolean }>`
  body {
    overflow: ${({ isChatOpen }) => (isChatOpen ? 'hidden' : 'auto')};
  }
  #___gatsby {
    overflow: ${({ isChatOpen }) => (isChatOpen ? 'hidden' : 'auto')};
    position: ${({ isChatOpen }) => (isChatOpen ? 'fixed' : 'static')};
    width: 100%;
  }
`;

export function ChatWidgetV3({ apiUrl }: ChatWidgetProps) {
  const [input, setInput] = useState<string>('');
  const [chatHistory, setChatHistory] = useState<ChatMessage[]>(() => {
    if (typeof window !== 'undefined') {
      const savedChatHistory = getWithExpiry('chatHistory');

      if (!savedChatHistory || typeof savedChatHistory !== 'string') {
        return [];
      }

      try {
        return JSON.parse(savedChatHistory);
      } catch (error) {
        console.error("Erreur lors de l'analyse de chatHistory:", error);
        return [];
      }
    }
  });

  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [currentTypingMessage, setCurrentTypingMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showZendeskForm, setShowZendeskForm] = useState<boolean>(false);
  const [tokenImages, setTokenImages] = useState<string[]>([]);
  const [showGdprMessage, setShowGdprMessage] = useState<boolean>(true);
  const [showAdvisorTab, setShowAdvisorTab] = useState<boolean>(false);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const [ticketId, setTicketId] = useState<null | number>(0);
  const [hasFilledInitialForm, setHasFilledInitialForm] = useState<boolean>(() => {
    const savedValue = getWithExpiry('hasFilledInitialForm');
    return savedValue ? JSON.parse(savedValue) : false;
  });

  const [showCallbackForm, setShowCallbackForm] = useState<boolean>(false);
  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [formState, setFormState] = useState<FormState>(() => {
    const savedFormState = getWithExpiry('formState');
    return savedFormState
      ? JSON.parse(savedFormState)
      : {
          userName: '',
          email: '',
          phoneNumber: '',
          reason: '',
          subject: '',
          description: '',
          requestType: '',
          secondRequestType: '',
          requestTypeID: 0,
          secondRequestTypeID: 0,
          quote_number: 0,
          delivery_points_number: 0,
          order_number: 0,
          account_number: 0,
          attachment_token: '',
          formType: 'ticket'
        };
  });

  useEffect(() => {
    const ttl = 30 * 60 * 1000; // 30 minutes
    setWithExpiry('chatHistory', chatHistory, ttl);
    setWithExpiry('formState', formState, ttl);
    setWithExpiry('hasFilledInitialForm', hasFilledInitialForm, ttl);
  }, [chatHistory, formState, hasFilledInitialForm]);

  useEffect(() => {
    if (isOpen) {
      if (chatHistory.length === 0 && !hasSentInitialMessage) {
        setChatHistory([
          [
            'exabot',
            'Bonjour ! Je suis Exabot, votre assistant virtuel. Je suis un chatbot en version bêta. Cela signifie que je continue à apprendre et à m’améliorer grâce à vos interactions. Comment puis-je vous aider aujourd’hui ?'
          ]
        ]);
        setHasSentInitialMessage(true);
      }
    } else {
      setHasSentInitialMessage(false);
    }
  }, [isOpen, chatHistory, hasSentInitialMessage]);

  useEffect(() => {
    if (!isOpen) return;
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [isOpen, chatHistory, currentTypingMessage, isTyping, showAdvisorTab, showZendeskForm, showCallbackForm]);
  const prefix = process.env.GATSBY_ENVIRONMENT == 'production' ? 'prd' : 'dev';

  const baseUrl = `https://ai-api-${prefix}.exaprint.fr/`;
  const zendeskSubmitTicket = 'submit-zendesk-form/';
  const urlSubmitZendeskForm = baseUrl + zendeskSubmitTicket;

  const phoneNumberRegex = /^\+?[0-9]{1,15}$/;

  const isMobile = useIsWindowWidthBelow(600);

  const validRequestTypes = [
    'accompagnement_devis',
    'informations_sur_une_commande_en_cours_',
    'information_sur_votre_réclamation',
    'informations_sur_mon_compte'
  ];

  const handleChangeForm: TypeHandleChangeForm = evt => {
    const { name, value } = evt.target;

    if (name === 'phoneNumber') {
      const cleanedValue = value.replace(/[^\d+]/g, '');
      if ((cleanedValue === '+' && value.length === 1) || phoneNumberRegex.test(cleanedValue) || cleanedValue === '') {
        setFormState(prev => ({ ...prev, phoneNumber: cleanedValue }));
        setErrors(prev => ({ ...prev, phoneNumber: '' }));
      } else {
        setErrors(prev => ({ ...prev, phoneNumber: 'Format invalide. Seuls les chiffres et "+" sont autorisés.' }));
      }
    } else {
      setFormState(prev => ({ ...prev, [name]: value }));
    }
  };

  const GdprMessage = () => (
    <GdprContainer>
      <GdprText>
        ExaBot a été conçu pour vous permettre d'accéder rapidement aux informations dont vous avez besoin. Ce chatbot utilise l’intelligence artificielle pour
        fournir des réponses pertinentes sur les produits et les services d'Exaprint. Nous vous remercions de l’utiliser uniquement pour poser des questions
        liées à ces sujets dans le respect des règles de courtoisie et de confidentialité.
        <br /> Vous pouvez consulter notre{' '}
        <PrivacyLink
          href="https://www.exaprint.fr/imprimerie-en-ligne/politique-de-confidentialite?srsltid=AfmBOoqImLxB882x5GlKdtkX_BhkdYaQsRes-dwikDGotPgSzM4UF_Ko"
          target="_blank"
          rel="noopener noreferrer"
        >
          politique de confidentialité
        </PrivacyLink>{' '}
        pour en savoir plus sur la gestion de vos données.
      </GdprText>
      <CloseGdprButton onClick={() => setShowGdprMessage(false)}>
        <X size={20} />
      </CloseGdprButton>
    </GdprContainer>
  );

  const handleRequestType = (evt: ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value as RequestTypeKey;
    setFormState({
      ...formState,
      requestType: value,
      requestTypeID: mappingFunc(value),
      secondRequestType: '',
      secondRequestTypeID: 0
    });
  };

  const handleSecondRequestType = (evt: ChangeEvent<HTMLSelectElement>) => {
    const value = evt.target.value as RequestTypeKey;
    setFormState({
      ...formState,
      secondRequestType: value,
      secondRequestTypeID: mappingFunc(value)
    });
  };

  const callExternalApi = async (input: string): Promise<ChatMessageResponse | undefined> => {
    try {
      setIsLoading(true);
      const response = await axios.post(apiUrl, {
        input: {
          ticket_id: ticketId,
          question: input,
          chat_history: chatHistory,
          name: formState.userName,
          email: isValidEmail(formState.email) ? formState.email : 'user@example.com'
        }
      });

      response?.data?.output?.ticket_id && setTicketId(response.data.output.ticket_id);

      setIsLoading(false);
      return response.data.output as ChatMessageResponse;
    } catch (error) {
      setIsLoading(false);
      console.error("Erreur lors de l'appel à l'API:", error);
      return undefined;
    }
  };

  const typeMessage = async (message: string) => {
    setIsTyping(true);
    const chunkSize = 5;
    for (let i = 0; i <= message.length; i += chunkSize) {
      setCurrentTypingMessage(message.slice(0, i));
      await new Promise(resolve => setTimeout(resolve, 10));
    }
    setIsTyping(false);
    setChatHistory(prevHistory => [...prevHistory, ['exabot', message]]);
    setCurrentTypingMessage('');
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const humanMessage: ChatMessage = ['client', input];
    setChatHistory(prevHistory => [...prevHistory, humanMessage]);
    setInput('');

    const aiResponse = await callExternalApi(input);
    aiResponse != undefined && typeMessage(aiResponse.answer);
  };

  const apiZendeskCall = async (formState: FormState, tokenImages: string[], chatHistory: ChatMessage[], urlSubmitZendeskForm: string) => {
    try {
      await axios.post(urlSubmitZendeskForm, {
        form_state: formState,
        token_images: tokenImages,
        chat_history: chatHistory
      });
    } catch (error) {
      console.error("Erreur lors de l'appel à l'API:", error);
      return undefined;
    }
  };

  function validateForm(formState: FormState, formType: 'ticket' | 'callback', showZendeskForm: boolean, phoneNumberRegex: RegExp) {
    const newErrors: { [key: string]: string } = {};

    if (!formState.userName.trim()) {
      newErrors.userName = 'Le nom est requis.';
    }

    if (!formState.email.trim()) {
      newErrors.email = "L'email est requis.";
    } else if (!isValidEmail(formState.email)) {
      newErrors.email = "L'email n'est pas valide.";
    }

    if (!formState.requestType.trim()) {
      newErrors.requestType = 'Veuillez sélectionner un objet pour la demande.';
    }

    if (!formState.secondRequestType.trim()) {
      newErrors.secondRequestType = 'Veuillez préciser votre demande.';
    }

    if (!formState.description.trim()) {
      newErrors.description = 'La description est requise.';
    }

    if (formType === 'ticket') {
      if (!formState.subject.trim()) {
        newErrors.subject = 'Le sujet est requis.';
      }
      if (!showZendeskForm) {
        if (!formState.phoneNumber.trim()) {
          newErrors.phoneNumber = 'Numéro de téléphone requis.';
        } else if (!phoneNumberRegex.test(formState.phoneNumber)) {
          newErrors.phoneNumber = 'Numéro de téléphone invalide. Seuls les chiffres et “+” sont autorisés.';
        }
      }
    } else {
      if (!formState.phoneNumber.trim()) {
        newErrors.phoneNumber = 'Numéro de téléphone requis.';
      } else if (!phoneNumberRegex.test(formState.phoneNumber)) {
        newErrors.phoneNumber = 'Numéro de téléphone invalide. Seuls les chiffres et “+” sont autorisés.';
      }
    }

    if (!Number.isInteger(Number(formState.order_number))) {
      newErrors.order_number = 'Renseignez uniquement des chiffres';
    }
    if (!Number.isInteger(Number(formState.quote_number))) {
      newErrors.quote_number = 'Renseignez uniquement des chiffres';
    }
    if (!Number.isInteger(Number(formState.delivery_points_number))) {
      newErrors.delivery_points_number = 'Renseignez uniquement des chiffres';
    }
    if (!Number.isInteger(Number(formState.account_number))) {
      newErrors.account_number = 'Renseignez uniquement des chiffres';
    }

    return {
      newErrors,
      isValid: Object.keys(newErrors).length === 0
    };
  }

  async function handleFormSubmit(
    e: FormEvent<HTMLFormElement>,
    formType: 'ticket' | 'callback',
    formState: FormState,
    setFormState: React.Dispatch<React.SetStateAction<FormState>>,
    setErrors: React.Dispatch<React.SetStateAction<{ [key: string]: string }>>,
    setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
    setChatHistory: React.Dispatch<React.SetStateAction<ChatMessage[]>>,
    setShowZendeskForm: React.Dispatch<React.SetStateAction<boolean>>,
    setShowCallbackForm: React.Dispatch<React.SetStateAction<boolean>>,
    urlSubmitZendeskForm: string,
    tokenImages: string[],
    chatHistory: ChatMessage[],
    showZendeskForm: boolean
  ) {
    e.preventDefault();
    setIsSubmitting(true);

    const { newErrors, isValid } = validateForm(formState, formType, showZendeskForm, phoneNumberRegex);

    if (!isValid) {
      setErrors(newErrors);
      setIsSubmitting(false);
      return;
    }

    setErrors({});

    const finalFormState =
      formType === 'callback' ? { ...formState, reason: 'callback', formType: 'callback' as const } : { ...formState, formType: 'ticket' as const };

    try {
      await apiZendeskCall(finalFormState, tokenImages, chatHistory, urlSubmitZendeskForm);

      if (formType === 'ticket') {
        setShowZendeskForm(false);
        setChatHistory(prev => [...prev, ['exabot', 'Votre demande a été transmise à notre équipe avec succès.']]);
      } else {
        setShowCallbackForm(false);
        setChatHistory(prev => [...prev, ['exabot', 'Votre demande de rappel a été enregistrée avec succès. Un conseiller vous contactera prochainement.']]);
      }

      setFormState({
        ...formState,
        phoneNumber: '',
        requestType: '',
        secondRequestType: '',
        secondRequestTypeID: 0,
        requestTypeID: 0,
        subject: '',
        description: '',
        quote_number: 0,
        delivery_points_number: 0,
        order_number: 0,
        account_number: 0,
        attachment_token: '',
        formType: 'ticket'
      });
    } catch (error) {
      console.error('Erreur lors de la création de la demande:', error);
      alert('Une erreur est survenue lors de la création de la demande. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  }

  function isValidEmail(email: string): boolean {
    const re = /^[A-Za-z0-9._%+\-]+@(?!.*\.\.)[A-Za-z0-9.\-]+\.[A-Za-z]{2,}$/;
    return re.test(email);
  }

  const handleInitialFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setHasFilledInitialForm(true);
  };

  interface ChatButtonProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
  }

  const ChatButtonPill = ({ isOpen, setIsOpen }: ChatButtonProps) => {
    return (
      <ButtonContainer>
        <PillToggleButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <X size={24} /> : <MessageCircle size={24} />}
          <ButtonText>Chat</ButtonText>
        </PillToggleButton>
      </ButtonContainer>
    );
  };

  return (
    <>
      {isMobile && <GlobalStyle isChatOpen={isOpen} />}
      {isOpen && isMobile && <StyledOverlay onClick={() => setIsOpen(false)} />}
      {isOpen && (
        <ChatContainer>
          <ChatWindow>
            <ChatHeader>
              {showAdvisorTab || showZendeskForm || showCallbackForm ? (
                <>
                  <BackButton
                    onClick={() => {
                      if (showCallbackForm) {
                        setShowCallbackForm(false);
                        setShowAdvisorTab(true);
                      }
                      if (showZendeskForm) {
                        setShowZendeskForm(false);
                        setShowAdvisorTab(true);
                      } else {
                        setShowAdvisorTab(false);
                      }
                      if (lastMessageRef.current) {
                        lastMessageRef.current.scrollIntoView({ behavior: 'auto' });
                      }
                    }}
                  >
                    <ChevronLeft size={24} />
                  </BackButton>
                  <ChatTitle>{showCallbackForm ? 'Être rappelé par un agent' : showZendeskForm ? 'Envoyer votre demande' : "Joindre l'assistance"}</ChatTitle>
                  <CloseButton onClick={() => setIsOpen(!isOpen)}>{isMobile ? <X size={24} /> : <ChevronDown size={24} />}</CloseButton>
                </>
              ) : (
                <>
                  <HeaderLeft>
                    <AvatarContainer>
                      <StyledIcon src={aiIcon} alt="ExaBot"></StyledIcon>
                      <OnlineBadge />
                    </AvatarContainer>
                    <ChatTitle>ExaBot</ChatTitle>
                  </HeaderLeft>
                  <AdvisorButton onClick={() => setShowAdvisorTab(true)}>Joindre l'assistance</AdvisorButton>
                  <CloseButton onClick={() => setIsOpen(!isOpen)}>{isMobile ? <X size={24} /> : <ChevronDown size={24} />}</CloseButton>
                </>
              )}
            </ChatHeader>
            <ChatBody>
              <ChatContent>
                {showAdvisorTab ? (
                  <AdvisorTab>
                    <TeamImages>
                      {[user1, user2, user3].map((userImage, index) => (
                        <ImageContainer key={index}>
                          <img src={userImage} alt={`Conseiller ${index + 1}`} />
                        </ImageContainer>
                      ))}
                    </TeamImages>
                    <AdvisorTitle>Notre équipe vous répond dans les plus brefs délais !</AdvisorTitle>
                    <AdvisorParagraph>
                      Vous pouvez demander à être rappelé par un conseiller ou nous soumettre directement votre demande via un formulaire.
                    </AdvisorParagraph>
                    <AdvisorButtons>
                      <YellowButton
                        onClick={() => {
                          setShowAdvisorTab(false);
                          setShowCallbackForm(true);
                        }}
                      >
                        Être rappelé par un conseiller
                      </YellowButton>
                      <YellowButton
                        isForm
                        onClick={() => {
                          setShowAdvisorTab(false);
                          setShowZendeskForm(true);
                        }}
                      >
                        Envoyer votre demande
                      </YellowButton>
                    </AdvisorButtons>
                  </AdvisorTab>
                ) : showZendeskForm ? (
                  <StyledForm
                    onSubmit={(e: any) =>
                      handleFormSubmit(
                        e,
                        'ticket',
                        formState,
                        setFormState,
                        setErrors,
                        setIsSubmitting,
                        setChatHistory,
                        setShowZendeskForm,
                        setShowCallbackForm,
                        urlSubmitZendeskForm,
                        tokenImages,
                        chatHistory,
                        showZendeskForm
                      )
                    }
                  >
                    <StyledInput name="userName" placeholder="Nom" value={formState.userName} onChange={handleChangeForm} required />
                    {errors.userName && <ErrorMessage>{errors.userName}</ErrorMessage>}
                    <StyledInput name="email" type="email" placeholder="Email" value={formState.email} onChange={handleChangeForm} required />
                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                    <StyledSelect name="requestType" value={formState.requestType} onChange={handleRequestType} required>
                      <option value="">Objet de la demande</option>
                      <option value="informations_d_ordre_générale">Obtenir des informations d'ordre générale</option>
                      <option value="passer_une_nouvelle_commande_">Passer une nouvelle commande</option>
                      <option value="accompagnement_devis">Accompagnement sur un devis</option>
                      <option value="informations_sur_une_commande_en_cours_">Obtenir des informations sur une commande en cours</option>
                      <option value="information_sur_votre_réclamation">Obtenir des informations sur une réclamation</option>
                      <option value="informations_sur_mon_compte">Obtenir des informations sur votre compte</option>
                      <option value="information_sur_vos_factures_vos_reglements">Obtenir une information sur vos factures / vos règlements</option>
                    </StyledSelect>
                    {errors.requestType && <ErrorMessage>{errors.requestType}</ErrorMessage>}
                    {formState.requestType !== '' && (
                      <StyledSelect name="secondRequestType" value={formState.secondRequestType} onChange={handleSecondRequestType} required>
                        {renderSecondLevel(formState.requestType)}
                      </StyledSelect>
                    )}
                    {formState.requestType !== '' && errors.secondRequestType && <ErrorMessage>{errors.secondRequestType}</ErrorMessage>}
                    {validRequestTypes.includes(formState.requestType) && (
                      <>{renderOptionalFields(formState.requestType, formState.secondRequestType, handleChangeForm, formState, errors)}</>
                    )}
                    <StyledInput name="subject" placeholder="Sujet" value={formState.subject} onChange={handleChangeForm} required />
                    {errors.subject && <ErrorMessage>{errors.subject}</ErrorMessage>}
                    <StyledTextarea
                      name="description"
                      placeholder="Description"
                      value={formState.description}
                      onChange={handleChangeForm}
                      maxLength="800"
                      required
                    />
                    {errors.description && <ErrorMessage>{errors.description}</ErrorMessage>}
                    <SimpleFileUpload setTokenImages={setTokenImages} isMobile={isMobile} />
                    <StyledButton
                      type="submit"
                      onClick={(e: any) =>
                        handleFormSubmit(
                          e,
                          'ticket', // <-- on lui dit qu'on soumet un ticket
                          formState,
                          setFormState,
                          setErrors,
                          setIsSubmitting,
                          setChatHistory,
                          setShowZendeskForm,
                          setShowCallbackForm,
                          urlSubmitZendeskForm,
                          tokenImages,
                          chatHistory,
                          showZendeskForm
                        )
                      }
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Envoi en cours...' : 'Envoyer ma demande'}
                    </StyledButton>
                  </StyledForm>
                ) : showCallbackForm ? (
                  <StyledForm
                    onSubmit={(e: any) =>
                      handleFormSubmit(
                        e,
                        'callback',
                        formState,
                        setFormState,
                        setErrors,
                        setIsSubmitting,
                        setChatHistory,
                        setShowZendeskForm,
                        setShowCallbackForm,
                        urlSubmitZendeskForm,
                        tokenImages,
                        chatHistory,
                        showZendeskForm
                      )
                    }
                  >
                    <StyledInput name="userName" placeholder="Nom" value={formState.userName} onChange={handleChangeForm} maxLength="64" required />
                    {errors.userName && <ErrorMessage>{errors.userName}</ErrorMessage>}
                    <StyledInput name="email" type="email" placeholder="Email" value={formState.email} onChange={handleChangeForm} maxLength="320" required />
                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                    <StyledInput name="phoneNumber" placeholder="Numéro de téléphone" value={formState.phoneNumber} onChange={handleChangeForm} required />
                    {errors.phoneNumber && <ErrorMessage>{errors.phoneNumber}</ErrorMessage>}
                    <StyledSelect name="requestType" value={formState.requestType} onChange={handleRequestType} required>
                      <option value="">Objet de la demande</option>
                      <option value="informations_d_ordre_générale">Obtenir des informations d'ordre général</option>
                      <option value="passer_une_nouvelle_commande_">Passer une nouvelle commande</option>
                      <option value="accompagnement_devis">Accompagnement sur un devis</option>
                      <option value="informations_sur_une_commande_en_cours_">Obtenir des informations sur une commande en cours</option>
                      <option value="information_sur_votre_réclamation">Obtenir des informations sur une réclamation</option>
                      <option value="informations_sur_mon_compte">Obtenir des informations sur votre compte</option>
                      <option value="information_sur_vos_factures_vos_reglements">Obtenir une information sur vos factures / vos règlements</option>
                    </StyledSelect>
                    {errors.requestType && <ErrorMessage>{errors.requestType}</ErrorMessage>}
                    {formState.requestType !== '' && (
                      <StyledSelect name="secondRequestType" value={formState.secondRequestType} onChange={handleSecondRequestType} required>
                        {renderSecondLevel(formState.requestType)}
                      </StyledSelect>
                    )}
                    {formState.requestType !== '' && errors.secondRequestType && <ErrorMessage>{errors.secondRequestType}</ErrorMessage>}
                    {validRequestTypes.includes(formState.requestType) && (
                      <>{renderOptionalFields(formState.requestType, formState.secondRequestType, handleChangeForm, formState, errors)}</>
                    )}

                    <StyledTextarea
                      name="description"
                      placeholder="Description"
                      value={formState.description}
                      onChange={handleChangeForm}
                      maxLength="800"
                      required
                    />
                    {errors.description && <ErrorMessage>{errors.description}</ErrorMessage>}
                    <StyledButton
                      type="submit"
                      onClick={(e: any) =>
                        handleFormSubmit(
                          e,
                          'callback',
                          formState,
                          setFormState,
                          setErrors,
                          setIsSubmitting,
                          setChatHistory,
                          setShowZendeskForm,
                          setShowCallbackForm,
                          urlSubmitZendeskForm,
                          tokenImages,
                          chatHistory,
                          showZendeskForm
                        )
                      }
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Envoi en cours...' : 'Envoyer la demande de rappel'}
                    </StyledButton>
                  </StyledForm>
                ) : !hasFilledInitialForm ? (
                  <InitialForm onSubmit={handleInitialFormSubmit}>
                    <InfoText>
                      Pour mieux vous accompagner et répondre à vos questions, merci de renseigner votre nom et votre email avant de démarrer le chat.
                    </InfoText>
                    <StyledInput name="userName" placeholder="Nom" value={formState.userName} onChange={handleChangeForm} required />
                    {errors.userName && <ErrorMessage>{errors.userName}</ErrorMessage>}

                    <StyledInput name="email" type="email" placeholder="Email" value={formState.email} onChange={handleChangeForm} required />
                    {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}

                    <StyledButton type="submit">Démarrer le chat</StyledButton>
                  </InitialForm>
                ) : (
                  <ChatList>
                    {chatHistory.map((message, index) => {
                      const isUser = message[0] === 'client';
                      const isLastMessage = index === chatHistory.length - 1;
                      const textContent = message[1];
                      const messageLength = textContent.length;
                      return (
                        <ChatItem key={index} isUser={isUser}>
                          {!isUser && <Avatar src={aiIcon} alt="ExaBot" isUser={isUser} />}
                          <ChatBubble isUser={isUser} ref={isLastMessage ? lastMessageRef : null} messageLength={messageLength}>
                            <MessageContent content={message[1]} />
                          </ChatBubble>
                        </ChatItem>
                      );
                    })}
                    {isLoading && (
                      <ChatItem isUser={false}>
                        <Avatar src={aiIcon} alt="ExaBot" isUser={false} />
                        <ChatBubble isUser={false} messageLength={50}>
                          <LoadingDots>
                            <Dot>.</Dot>
                            <Dot>.</Dot>
                            <Dot>.</Dot>
                          </LoadingDots>
                        </ChatBubble>
                      </ChatItem>
                    )}
                    {isTyping && currentTypingMessage && (
                      <ChatItem isUser={false}>
                        <Avatar src={aiIcon} alt="ExaBot" isUser={false} />
                        <ChatBubble isUser={false} ref={lastMessageRef} messageLength={currentTypingMessage.length}>
                          <MessageContent content={currentTypingMessage} />
                        </ChatBubble>
                      </ChatItem>
                    )}
                  </ChatList>
                )}
              </ChatContent>

              {showGdprMessage && !showAdvisorTab && !showZendeskForm && !showCallbackForm && hasFilledInitialForm && chatHistory.length <= 1 && (
                <GdprMessage />
              )}
            </ChatBody>

            {!showAdvisorTab && !showZendeskForm && !showCallbackForm && hasFilledInitialForm && (
              <ChatFooter>
                <ChatForm onSubmit={handleSubmit}>
                  <ChatInput
                    placeholder="Votre message..."
                    value={input}
                    onChange={(e: any) => {
                      setInput(e.target.value);
                      if (showGdprMessage) {
                        setShowGdprMessage(false);
                      }
                    }}
                    disabled={!hasFilledInitialForm || isLoading}
                  />
                  <SendButton type="submit" disabled={!input.trim()}>
                    <ArrowUp size={24} />
                  </SendButton>
                </ChatForm>
              </ChatFooter>
            )}
          </ChatWindow>
        </ChatContainer>
      )}
      <ChatButtonPill isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.4);
  }
  70% {
    transform: scale(1.07);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
`;

const ButtonText = styled.span`
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
`;

const PillToggleButton = styled.button`
  background-color: #51c1ec;
  color: white;
  border: none;
  border-radius: 28px;
  height: 56px;
  padding: 0 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  transition: all 0.3s ease;
  animation: ${pulse} 2s infinite;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);

  box-shadow: 0px 38px 11px 0px rgba(0, 0, 0, 0), 0px 24px 10px 0px rgba(0, 0, 0, 0.01), 0px 14px 8px 0px rgba(0, 0, 0, 0.05),
    0px 6px 6px 0px rgba(0, 0, 0, 0.09), 0px 2px 3px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #4caed1;
  }
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;

  @media (min-width: 600px) {
    display: none;
  }
`;

const ChatContainer = styled.div<{ customHeight: number }>`
  position: fixed;
  bottom: 9.5%;
  right: 2.5%;
  z-index: 10000;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  max-height: 80vh;
  @media (max-width: 600px) {
    bottom: 0;
    right: 0;
    width: 100%;
    max-height: 100vh;
    height: ${props => (props.customHeight ? '70vh' : '100%')};
  }
`;

export const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 8px;
  text-align: left;
  width: 100%;
`;

const LoadingDots = styled.span`
  display: inline-flex;
  justify-content: space-between;
  width: 1.5em;
`;

const Dot = styled.span`
  font-size: 1.5em;
  color: #000;
  animation: ${keyframes`
    0% {
      opacity: 0.2;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-5px);
    }
    100% {
      opacity: 0.2;
      transform: translateY(0);
    }
  `} 1.5s infinite;
  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-delay: 0.6s;
  }
`;

const ChatWindow = styled.div`
  background-color: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  width: 420px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  min-height: 80vh;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: 0;
    max-height: none;
  }
`;

const ChatHeader = styled.header`
  background-color: #337c99;
  color: white;
  padding: 16px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;

  @media (max-width: 600px) {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #e5e7eb;
`;

const ChatTitle = styled.h1`
  font-size: 14px;
  line-height: 21px;
  font-weight: normal !important;
  font-family: 'StyreneA', sans-serif !important;
  text-align: center;
  flex-grow: 1;
`;

const AdvisorButton = styled.button`
  background: none;
  border: 1px solid white;
  color: white;
  font-size: 14px;
  font-weight: normal !important;
  font-family: 'StyreneA', sans-serif !important;
  cursor: pointer;
  padding: 6px 12px;
  border-radius: 999px;
  white-space: nowrap;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  border-radius: 999px;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const BackButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 999px;

  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.3);
  }
`;

const OnlineBadge = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #4caf50; // vert
  border: 2px solid #ffffff;
  border-radius: 50%;
`;

const AvatarContainer = styled.div`
  position: relative;
  display: flex;
`;

const PrivacyLink = styled.a`
  color: #ff3d77;
  text-decoration: underline;
  cursor: pointer;
`;

const InitialForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
`;

const InfoText = styled.p`
  font-size: 14px;
  color: #676767;
  text-align: center;
  margin-bottom: 10px;
  font-family: 'StyreneA', sans-serif !important;
  line-height: 21px !important;
`;

const ChatList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #ebebeb;
  width: 100%;
  flex-grow: 1;
`;

const ChatFooter = styled.div`
  padding: 8px;
  border-top: 1px solid #e5e7eb;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  background-color: #fff;
  position: sticky;
  bottom: 0;
`;

const ChatForm = styled.form`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ChatInput = styled.input<{ isMobile: boolean }>`
  flex-grow: 1;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  font-family: 'StyreneA', sans-serif !important;
  //word-wrap: break-word;
`;

const SendButton = styled.button<{ disabled: boolean }>`
  background-color: ${props => (props.disabled ? 'transparent' : '#337C99')};
  color: ${props => (props.disabled ? 'grey' : 'white')};
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  background-color: #337c99;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  font-family: 'StyreneA', sans-serif !important;
  width: 70%;
  font-size: 16px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;
  width: 100%;
  max-width: 360px;
  font-family: 'StyreneA', sans-serif !important;
  margin: 0 auto;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  resize: vertical;
  font-family: 'StyreneA', sans-serif !important;
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 10%;
  right: 3%;
  z-index: 9999;

  @media (max-width: 600px) {
    bottom: 2%;
    right: 4%;
  }
`;

const GdprContainer = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px 21px;
  position: relative;
  border-top: 1px solid #e5e7eb;
  flex-shrink: 0;
`;

const GdprText = styled.p`
  font-size: 12px;
  margin: 5px;
  font-size: 12px;
  font-family: 'StyreneA', sans-serif !important;
  line-height: 16px !important;
  color: #676767 !important;
`;

const CloseGdprButton = styled.button`
  position: absolute;
  top: 14px;
  right: 4px;
  background: none;
  border: none;
  cursor: pointer;
  color: #676767 !important;
`;

const TeamImages = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #e5e7eb;
  border: 2px solid white;
  margin-left: -15px;
  &:first-child {
    margin-left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AdvisorButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const YellowButton = styled.button<{ isUser: boolean }>`
  background-color: #ffc846;
  color: black;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-family: 'StyreneA', sans-serif !important;
  cursor: pointer;
  font-size: 14px;
  margin-bottom: 10px;
  width: ${props => (props.isForm ? '70%' : '80%')};
`;

const ChatBody = styled.div<{ windowHeight: number }>`
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: #ebebeb;
`;

const ChatContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  //overflow-y: auto;
  padding: 16px;
  background-color: #ebebeb;
`;

const AdvisorTab = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  gap: 16px;
  padding: 16px;
`;

const AdvisorTitle = styled.h2`
  font-size: 16px;
  text-align: center;
  margin: 0;
  font-family: 'StyreneA', sans-serif !important;
  //font-weight: 500 !important;
  line-height: 24px !important;
  width: 70%;
`;

const AdvisorParagraph = styled.p`
  font-size: 14px;
  text-align: center;
  margin: 0;
  font-family: 'StyreneA', sans-serif !important;
  font-weight: 100 !important;
  line-height: 21px !important;
  color: #676767;
  margin-bottom: 10px;
`;

const ChatItem = styled.li<{ isUser: boolean }>`
  display: flex;
  flex-direction: ${props => (props.isUser ? 'row-reverse' : 'row')};
  align-items: flex-start;
  margin-bottom: 16px;
`;

const Avatar = styled.img<{ isUser: boolean }>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: ${props => (props.isUser ? '0 0 0 10px' : '0 10px 0 0')};
`;

const ChatBubble = styled.div<{ isUser: boolean; messageLength: number }>`
  background-color: ${props => (props.isUser ? '#337C99' : '#ffffff')};
  color: ${props => (props.isUser ? '#ffffff' : '#000000')};
  border-radius: 16px;
  line-height: 1.2rem !important;
  padding: 12px;
  max-width: ${props => (props.messageLength < 120 ? '70%' : '100%')};
  word-wrap: break-word !important;
  align-self: flex-start;
`;

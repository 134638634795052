import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Trash } from 'lucide-react';
import axios from 'axios';

interface SimpleFileUploadProps {
  setTokenImages: (images: string[]) => void;
  isMobile: boolean;
}
const prefix = process.env.GATSBY_ENVIRONMENT == 'production' ? 'prd' : 'dev';

const uploadTicketImage = async (filename: string, file_content: string) => {
  try {
    const response = await axios.post(`https://ai-api-${prefix}.exaprint.fr/upload-file/`, {
      filename: filename,
      file_content: file_content
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'appel à l'API:", error);
    return undefined;
  }
};

export const SimpleFileUpload = ({ setTokenImages, isMobile }: SimpleFileUploadProps) => {
  const [messages, setMessages] = useState<string[]>([]);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<{ fileName: string; token: string }[]>([]);

  const MAX_FILE_SIZE_MB = 50;

  const convertFileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (typeof reader.result === 'string') {
          const base64String = reader.result.split(',')[1];
          resolve(base64String);
        } else {
          reject(new Error('Échec de la conversion du fichier en base64.'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Une erreur est survenue lors de la lecture du fichier.'));
      };

      reader.readAsDataURL(file);
    });
  };

  const handleFilesUpload = async (files: File[]) => {
    if (files.length === 0) return;

    setMessages([]);

    setUploading(true);

    const newMessages: string[] = [];
    const newFiles: { fileName: string; token: string }[] = [];

    for (const file of files) {
      try {
        const base64Content = await convertFileToBase64(file);
        const uploadResponseToken = await uploadTicketImage(file.name, base64Content);
        if (uploadResponseToken) {
          console.log(...[uploadResponseToken, '👀 [SimpleFileUpload.tsx:215]: uploadResponseToken'].reverse());
          newFiles.push({ fileName: file.name, token: uploadResponseToken });
        }
      } catch {
        newMessages.push(`Échec du téléchargement de ${file.name}.`);
      }
    }

    const updatedFiles = [...uploadedFiles, ...newFiles].slice(0, 5);
    setUploadedFiles(updatedFiles);
    setTokenImages(updatedFiles.map(file => file.token));

    setUploading(false);

    if (newMessages.length > 0) {
      setMessages(newMessages);
    }
  };

  const removeFile = (fileName: string) => {
    setMessages([]);
    const updatedFiles = uploadedFiles.filter(file => file.fileName !== fileName);
    setUploadedFiles(updatedFiles);
    setTokenImages(updatedFiles.map(file => file.token));
    setMessages((prev: string[]) => [...prev, `Le fichier ${fileName} a été retiré.`]);
  };

  const onDrop = (acceptedFiles: File[]) => {
    setMessages([]);

    const validFiles: File[] = [];
    const errorMessages: string[] = [];

    for (const file of acceptedFiles) {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        errorMessages.push(`Le fichier ${file.name} dépasse la taille limite de ${MAX_FILE_SIZE_MB} Mo.`);
      } else {
        validFiles.push(file);
      }
    }

    if (uploadedFiles.length + validFiles.length > 5) {
      errorMessages.push('Vous avez déjà téléchargé 5 fichiers.');
      console.log('max file !');
    } else if (validFiles.length > 0) {
      console.log('upload file');

      handleFilesUpload(validFiles.slice(0, 5 - uploadedFiles.length));
    }

    setMessages((prev: string[]) => [...prev, ...errorMessages]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    maxFiles: 5
  });

  return (
    <UploadContainer>
      <UploadTitle>Téléchargement de fichiers</UploadTitle>
      <Dropzone {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />
        {isMobile ? (
          <p>Cliquez pour sélectionner jusqu'à 5 fichiers.</p>
        ) : isDragActive ? (
          <p>Déposez les fichiers ici...</p>
        ) : (
          <p>Glissez et déposez jusqu'à 5 fichiers ici, ou cliquez pour en sélectionner.</p>
        )}
      </Dropzone>
      {uploading && <Message>Envoi en cours...</Message>}
      {messages.map((msg, index) => (
        <Message key={index} isError={msg.includes('dépasse') || msg.includes('déjà téléchargé')}>
          {msg}
        </Message>
      ))}
      <FilesContainer>
        {uploadedFiles.map((file, index) => (
          <Files key={index}>
            <span>{file.fileName}</span>
            <Trash size={16} onClick={() => removeFile(file.fileName)} style={{ color: 'red' }} />
          </Files>
        ))}
      </FilesContainer>
    </UploadContainer>
  );
};

const UploadContainer = styled.div`
  max-width: 400px;
  margin: 3rem auto;
  padding: 1.25rem;
  border: 1px solid #d1d5db;
  border-radius: 0.5rem;
  text-align: center;
  font-family: sans-serif;
`;

const UploadTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 1rem;
  font-family: 'Styrene Medium', sans-serif !important;
`;

const FilesContainer = styled.div`
  margin-top: 1rem;
`;

const Files = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const Dropzone = styled.div<{ isDragActive: boolean }>`
  border: 2px dashed #d1d5db;
  padding: 1.5rem;
  border-radius: 0.5rem;
  font-family: 'StyreneA', sans-serif !important;
  transition: border-color 0.2s, background-color 0.2s;
  ${props =>
    props.isDragActive &&
    `
    border-color: #3b82f6;
    background-color: #eff6ff;
  `}
`;

const Message = styled.p<{ isError?: boolean }>`
  margin-top: 1rem;
  color: ${props => (props.isError ? '#b42121' : '#0c7451')};
  margin-bottom: 1rem;
`;

import React from 'react';
import styled from 'styled-components';
import { RequestTypeKey } from '../../utils/chatbot/idFieldMapping';

const Option = styled.option``;

export const renderSecondLevel = (firstLevelType: RequestTypeKey) => {
  if (firstLevelType === 'informations_d_ordre_générale') {
    return (
      <>
        <Option value="">Précisez votre demande</Option>
        <Option value="s_inscrire__sur_exaprint">S'inscrire sur Exaprint</Option>
        <Option value="problèmes_rencontrés_sur_la_navigation_sur_le_site_web">Problèmes rencontrés sur la navigation sur le site web</Option>
      </>
    );
  }

  if (firstLevelType === 'passer_une_nouvelle_commande_') {
    return (
      <>
        <Option value="">Précisez votre demande</Option>
        <Option value="obtenir_une_information_sur_un_produit__une_finition__un_service">
          Obtenir une information sur un produit, une finition, un service
        </Option>
        <Option value="modalité_de_livraison">Modalité de livraison</Option>
        <Option value="préparation_de_votre_fichier">Préparation de votre fichier (PAO)</Option>
        <Option value="aide_sur_les_codes_promo">Aide sur les codes promo</Option>
        <Option value="règlement_de_votre_commande">Règlement de votre commande</Option>
        <Option value="demande_d_échantillons">Demande d'échantillons</Option>
      </>
    );
  }

  if (firstLevelType === 'accompagnement_devis') {
    return (
      <>
        <Option value="">Objet de votre demande de devis</Option>
        <Option value="demande_de_multipoint_point_de_livraison_sur_produit_gamme_catalogue__n°_de_devis_obligatoire_">
          Demande de multi-points de livraison sur devis existant (N° de devis requis)
        </Option>
        <Option value="demande_de_devis_sur_mesure_sur_produit_hors_gamme_catalogue__">Demande de devis sur produit non vendu en ligne</Option>
      </>
    );
  }

  if (firstLevelType === 'informations_sur_une_commande_en_cours_') {
    return (
      <>
        <Option value="">Précisez votre demande</Option>
        <Option value="délai_de_livraison">Délai de livraison</Option>
        <Option value="modification_de_l_adresse_de_livraison">Modification de l'adresse de livraison</Option>
        <Option value="annulation_de_commande">Annulation de commande</Option>
        <Option value="commande_bloquée__fichier">Commande bloquée (fichier)</Option>
        <Option value="probleme_de_livraison">Problème de livraison</Option>
        <Option value="commande_bloquee_paiement">Commande bloquée paiement</Option>
        <Option value="factures">Facture</Option>
        <Option value="autre">Autre</Option>
      </>
    );
  }

  if (firstLevelType === 'information_sur_votre_réclamation') {
    return (
      <>
        <Option value="">Précisez votre demande</Option>
        <Option value="reclamation_livraison">Réclamation relative à la livraison</Option>
        <Option value="reclamation_qualite_/_conformite_produit">Réclamation qualité / conformité produit</Option>
        <Option value="demande_reclamation_hors_delais">Solliciter la prise en charge d'une réclamation hors délais (plus d’un mois après la livraison)</Option>
      </>
    );
  }

  if (firstLevelType === 'informations_sur_mon_compte') {
    return (
      <>
        <Option value="">Précisez votre demande</Option>
        <Option value="programme_exaclub">Programme Exaclub</Option>
        <Option value="mise_à_jour_des_données_du_compte">Mise à jour des données du compte</Option>
        <Option value="suppression_de_compte__rgpd_">Suppression de compte</Option>
        <Option value="probleme_de_connexion__mot_de_passe_">Problème de connexion (mot de passe)</Option>
        <Option value="désinscription_de_la_news_letter">Désinscription de la newsletter</Option>
        <Option value="obtenir_des_informations_sur_mon_encours">Obtenir des informations sur mon encours</Option>
      </>
    );
  }

  if (firstLevelType === 'information_sur_vos_factures_vos_reglements') {
    return (
      <>
        <Option value="">Précisez votre demande</Option>
        <Option value="factures_et_releve">Factures et relevé de compte</Option>
        <Option value="informations_sur_un_reglement">Informations sur un règlement</Option>
        <Option value="mandat_administratif">Mandat administratif</Option>
        <Option value="ordre_virement">Ordre de virement</Option>
        <Option value="demande_de_prelevement">Demande de prélèvement</Option>
      </>
    );
  }

  return null;
};

export default renderSecondLevel;
